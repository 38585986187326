import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { defineCustomElements } from '@ionic/pwa-elements/loader';

import { initializeApp } from 'firebase/app'; // Import initializeApp

if (environment.production) {
  enableProdMode();
}

// Initialize Firebase app here
const firebaseConfig = {
  apiKey: "AIzaSyAoj4LEUsXjy_jbIA3qV1KJ2k-AXIPT2JQ",
  authDomain: "sahira-hotels.firebaseapp.com",
  projectId: "sahira-hotels",
  storageBucket: "sahira-hotels.appspot.com",
  messagingSenderId: "696853318422",
  appId: "1:696853318422:web:6c0d40b9cb82309a471e37",
  measurementId: "G-V601MGELKJ",
  vapidKey: "BLiwxCPXurhlDC0imZXp29yh8CGv9Gful419-cD47iP7YoWkwOSe0mZxJdDuGBjyevx5UYfl8SMue5NGJCyoysE"
};

initializeApp(firebaseConfig);

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/firebase-messaging-sw.js')
  .then((registration) => {
    console.log('Service Worker registered with scope:', registration.scope);
  })
  .catch((error) => {
    console.error('Service Worker registration failed:', error);
  });
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));

// Call the element loader after the platform has been bootstrapped
defineCustomElements(window);