// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  firebaseConfig: {
    apiKey: "AIzaSyAoj4LEUsXjy_jbIA3qV1KJ2k-AXIPT2JQ",
    authDomain: "sahira-hotels.firebaseapp.com",
    projectId: "sahira-hotels",
    storageBucket: "sahira-hotels.appspot.com",
    messagingSenderId: "696853318422",
    appId: "1:696853318422:web:6c0d40b9cb82309a471e37",
    measurementId: "G-V601MGELKJ",
    vapidKey: "BLiwxCPXurhlDC0imZXp29yh8CGv9Gful419-cD47iP7YoWkwOSe0mZxJdDuGBjyevx5UYfl8SMue5NGJCyoysE"
  },
  pusher: {
    key: 'accdd4c306488527a2ff',
    appId: '1732796',
    cluster: 'ap1',
  },
  apiKey: "AIzaSyDGIniVHn21ap8bz2iHhVkC-Dm8CTW5KM0",
  BASE_URL: "https://cms.salamdjourney.com/",
  API_URL: "https://cms.salamdjourney.com/cms/api",
  DOKU_MERCHANT_ID: 'BRN-0206-1706856229947',
  DOKU_SECRECT_KEY: 'SK-t688X3HJjy7KBRs7othG',
  DOKU_MERCHANT_ID_DEV: 'BRN-0257-1708430106108',
  DOKU_SECRECT_KEY_DEV: 'SK-J6H3BnhXU8OJ3etNiGSS',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
